// import React from 'react';
// import Helmet from 'react-helmet';

export const useTracking = () => {
  // Klaviyo Identify
  function identify(email, payload = {}) {
    global.klaviyo &&
      email &&
      global.klaviyo.push([
        'identify',
        {
          $email: email,
          ...payload,
        },
      ]);
  }
  // Klaviyo Track
  function track(action, payload = {}) {
    global.klaviyo &&
      action &&
      global.klaviyo.push([
        'track',
        action,
        {
          ...payload,
        },
      ]);
  }
  return { identify, track };
};
